.pb-img-wrapper {
  position: relative;
  width: 100%;

  &::before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    object-fit: cover;
    object-position: center;
    background: #f6f7f8;
  }

  &.img-animated img {
    animation: linear 1s placeHolderShimmer infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 350% 100%;
  }

  &.no-img {
    background-color: black;
  }

  &.4by3::before { padding-top: 75%; }
  &.square::before { padding-top: 100%; }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: 300px 0;
  }
}
