.navbar.navbar-light {
    padding: 0;
    background: white;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
    .navbar-brand {
        max-width: 70%;
        padding: 0;
        img {
            padding: 5px 0;
            max-width: 100%;
        }
    }
    .navbar-toggler {
        color: $secondary;
        border: 0;
        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
    }
    .navbar-nav {
        align-items: center;
    }
    .navbar-nav .nav-link {
        position: relative;
        font-weight: 600;
        font-size: 0.9rem;
        color: $black;
        padding: 30px 12px;
        transition: 200ms all;
        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0rem;
            left: 3px;
            height: 5px;
            width: calc(100% - 6px);
            background-color: white;
            opacity: 0;
            transition: 200ms all;
        }
        &.active,
        &:hover,
        &:focus,
        &:active {
            color: $secondary;
        }
        &.button {
            color: white;
            margin: 0;
            margin-left: 20px;
            padding: 0.75rem 1.5rem;
            &::before {
                content: none;
            }
        }
        @media (min-width: 992px) {
            &.active::before,
            &:hover::before,
            &:focus::before,
            &:active::before {
                opacity: 1;
            }
        }

    }
    .dropdown-menu {
        margin: 0;
        margin-top: -15px;
        border: 0;
        border-radius: 0;
        background-color: white;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
        .dropdown-item {
            font-weight: 500;
            color: black;
            transition: 200ms all;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                color: $secondary;
            }
        }
    }
}

.sidebar {
    text-align: left;
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 100%;
    top: 0;
    z-index: 300;
    bottom: 0;
    padding: 2rem 0;
    margin: 0;
    right: 0;
    border: 0;
    width: 250px;
    background-color: white;
    right: -300px;
    transition: right 100ms;
    overflow-y: scroll;
    &.active {
        right: 0;
        transition: right 100ms;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
    .nav-item .nav-link {
        font-weight: 500;
        font-size: 1rem;
        color: $secondary;
        padding: 8px 20px;
        transition: 200ms all;
        &.active {
            color: $secondary;
        }
        &.button {
            color: $secondary;
            margin: 8px 20px;
        }
    }
    .nav-item.indented {
      padding-left: 16px;
    }
    .bottom {
        text-align: center;
        margin-top: auto;
    }
}

body.sidebar-active {
    overflow: hidden;
    transition: 50ms all;
    .sidebar-overlay {
        content: "";
        display: block;
        z-index: 200;
        position: fixed;
        height: 500px;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        pointer-events: auto;
        transition: 50ms all;
    }
}

.dropdown-item {
  padding: .40rem 1.5rem;
}

.sidebar-close {
    z-index: 9994;
    width: 45px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: $secondary;
    font-style: normal;
    font-size: 30px;
    line-height: 45px;
    border: 0;
    background: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
