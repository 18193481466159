.pb-form {
  position: relative;
  width: 100%;

  &.pb-form--no-labels {
    label {
      display: block;
      margin: 1em 0 0 0;
      font-weight: bold;
      letter-spacing: -0.02em;
      line-height: 1.5;
      text-align: left;
    }
  }
}

.pb-input-wrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.pb-input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cccccc;
  padding: 10px 15px;
  font-size: 1rem;
  background-color: white;
  margin: 0;
  transition: 200ms all ease-in-out;

  &:focus, &:active {
    outline: none;
    border-color: var(--pb-primary-color);
  }

  &[disabled='disabled'], &[readonly='readonly'] {
    background-color: #f9f6f6;
  }
}

.pb-button {
  background-color: var(--pb-primary-color);
  border-color: var(--pb-primary-color);
  color: white;
  cursor: pointer;
}

.pb-event-registration-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 540px;
  box-shadow: 0 0 1em #e9e9e9;
  padding: 20px 1.5em;
  border-radius: 4px;

  .pb-lead {
    text-align: center;
  }

  .pb-icon-wrapper {
    border: none;
  }

  input:not([type=submit]) {
    background-color: #fcfdfd;
  }

  label {
    color: #3d5154;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}

.pb-button-disabled {
  cursor: no-drop;
  background-color: #e2e6e8 !important;
  color: #a8aaac !important;
  border-color: #e2e6e8 !important;
}

.pb-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pb-loader {
  display: inline-block;
  margin-right: 8px;
  border: 2px solid #e2e6e8;
  border-top: 2px solid #a8aaac;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}