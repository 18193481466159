.logo {
  max-height: 130px;
}

section {
  position: relative;
  padding: 5rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }
}


form {
    legend, label{
        font-size: 1rem;
    }
}

.hero {
  min-height: 450px;
  display: flex;
  align-items: center;
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(0, 58, 104, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.75);
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.bg-hero-intro {
  background: no-repeat 50% 20%;
  background-size: cover;
  background-image: url(../img/bg-hero-intro.jpg);
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-about-group.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
}

.bg-campus {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-registration {
    background: no-repeat 50% 60%;
    background-size: cover;
    background-image: url(../img/bg-registration.jpg);
}

.bg-hero-spark {
    background: no-repeat center top;
    background-size: cover;
    background-image: url(../img/bg-hero-spark.jpg);
}

.bg-hero-discovery {
  background: no-repeat center 35%;
  background-size: cover;
  background-image: url(../img/bg-hero-discovery.jpg);
}

.bg-hero-agora {
  background: no-repeat center 65%;
  background-size: cover;
  background-image: url(../img/bg-agora.jpg);
}

.bg-hero-lauchpad {
  background: no-repeat center 20%;
  background-size: cover;
  background-image: url(../img/bg-hero-launchpad.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-e-camp {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-curiosity {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-curiosity.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cta-community-elementary.jpg);
}

.testimonial {
  font-size: 1.5rem;
  line-height: 1.7;

  .content {
    font-weight: 200;
  }

  .attribution {
    color: $grey;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.25rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  p {
    font-weight: 300;
    font-size: 18px;
    color: $black;
    line-height: 1.82;
  }
}

.box {
  box-shadow: 5px 5px 25px 0px rgba(46,61,73,0.2);
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member, .icon {
  max-height: 80px;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: block;
    margin-bottom: 1rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.social-icons {
  margin-bottom: 3rem;;
  .social-icon {
    display: inline-block;
    margin: 0 3px;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 18px;
    text-align: center;
    color: white;
    background: $grey;
    border-radius: 50%;
    transition: 200ms all;

    &:hover {
      background: lighten($blue, 10%);
    }
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $light-blue;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.18em;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  text-align: left;
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-featured {
  max-height: 450px;
}

.center-flex {
  display: flex;
  align-items: center;
}

.play-ted {
  height: 25px;
  margin-left: 7px;
  margin-top: -2px;
}

.carousel{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .carousel-item{
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
  }
}

.bg-light-blue {
  background-color: #6ed2ee;
}
.bg-light-blue {
  background-color: #6ed2ee;
}
.light-yellow {
  background-color: #f5c116;
}
.orange {
  background-color: #f47122;
}
.logo-bootcamp {
  width: 200px;
  margin-bottom: 20px;
}
.camp .section-title span {
  display: block;
  margin-top: 8px;
  font-size: 22px;
}

.form-banner {
  .email {
    text-decoration: underline;
  }
}
