$green: #27ae60;
$primary: #27ae60;
$blue: #003a68;
$secondary: #0063a8;
$light-blue: #3498db;
$red: #e74c3c;
$yellow: #EAAD21;
$orange: rgb(245,120,79);
$purple: #4D43C1;
$black: #1b2733;
$grey: #637282;
$bg-grey: #f7f8fb;

.blue { color: $blue; }
.light-blue { color: $light-blue; }
.red { color: $red; }
.green { color: $green; }
.yellow { color: $yellow; }
.purple { color: $purple; }
.grey { color: $grey; }
.white { color: white; }

.bg-yellow { background-color: $yellow; }
.bg-blue { background-color: $blue; }
.bg-light-blue { background-color: $light-blue; }
.bg-red { background-color: $red; }
.bg-green { background-color: $green; }
.bg-white { background-color: white; }
.bg-grey { background-color: $bg-grey; }
.bg-purple { background-color: $purple; }
