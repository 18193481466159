.m0 {
  margin: 0;
}

.m1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m1sides {
  margin-left: 1rem;
  margin-right: 1rem;
}

.m2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mthalf {
  margin-top: 0.5rem;
}

.mt0 {
  margin-top: 0rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mbhalf {
  margin-bottom: 0.5rem;
}

.mb0 {
  margin-bottom: 0;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mr {
  margin-right: 0.33rem;
}

.mr1 {
  margin-right: 0.66rem;
}

.ml {
  margin-left: 0.33rem;
}

.ml1 {
  margin-left: 0.55rem;
}

.p0 {
  padding: 0;
}

.phalf {
  padding: 0.5rem;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.pt0 {
  padding-top: 0;
}

.pb0 {
  padding-bottom: 0;
}

.pb1 {
  padding-bottom: 1rem;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.pl1 {
  padding-left: 1rem;
}

.pr1 {
  padding-right: 1rem;
}

.p1a {
  padding: 1rem;
}

.p2a {
  padding: 2rem;
}

.mb1-mobile { @media (max-width: 991px) { margin-bottom: 1rem } }
.mb2-mobile { @media (max-width: 991px) { margin-bottom: 2rem } }
.mt1-mobile { @media (max-width: 991px) { margin-top: 1rem } }
.mt2-mobile { @media (max-width: 991px) { margin-top: 2rem } }
