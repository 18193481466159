.pb-popup {
  z-index: 100;
  position: fixed;
  bottom: -10px;
  right: 20px;
  margin-left: 20px;
  width: 376px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16);
  box-shadow: 0 5px 40px rgba(0,0,0,.16);
  border-radius: 8px;
  background-color: white;
  opacity: 0;
  transition: 200ms all ease-out;
  pointer-events: none;
  overflow: auto;

  &.active {
    opacity: 1;
    bottom: 20px;
    pointer-events: all;
  }

  .pb-popup-header {
    position: relative;
    min-height: 75px;
    background: linear-gradient(135deg,rgb(35, 146, 236),rgb(13, 92, 156));
    background-blend-mode: overlay;
    color: #fff;
    -webkit-transition: height .16s ease-out;
    transition: height .16s ease-out;
    padding: 30px;
    text-align: center;

    img {
      margin-bottom: 8px;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 50%;
    }

    .title {
      font-weight: 600;
      font-size: 1.1rem;
      margin: 0 0 8px;
      color: white;
    }

    p {
      margin: 0;
      color: rgba(white, 0.8);
      &:last-child { margin-bottom: 0; }
    }
  }

  .pb-popup-content {
    padding: 30px;
  }

  .close {
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    z-index: 10;
    font-weight: 300;
  }
}

.pb-popup-preview {
  position: fixed;
  bottom: -40px;
  right: 20px;
  text-align: center;
  -webkit-box-shadow: 0 5px 40px rgba(0,0,0,.16);
  box-shadow: 0 5px 40px rgba(0,0,0,.16);
  border-radius: 8px 8px 0 0;
  padding: 12px 16px 12px;
  color: white;
  background: linear-gradient(135deg,rgb(35, 146, 236),rgb(13, 92, 156));
  background-blend-mode: overlay;
  opacity: 0;
  transition: 200ms all ease-out;
  pointer-events: none;
  z-index: 100;

  &.active {
    opacity: 1;
    bottom: 0;
    pointer-events: all;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
  }
}
