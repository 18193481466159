.pb-sticky-bar {
  display: none;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 40px;
  background-color: #007bff;
  color: white;
  font-weight: 500;
  text-decoration: none;
  transition: 200ms all ease-in;

  .pb-sticky-bar--link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .pb-sticky-bar--content a {
    color: inherit;
    text-decoration: underline;
  }

  .pb-sticky-bar--close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0,0,0,.15);
    color: #fff;
    height: 100%;
    width: 32px;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &:hover, &:focus, &:active {
    color: white;
    text-decoration: none;
    background-position: 100% 0;
  }

  &.show {
    display: flex;
  }

  @media(max-width: 991px) {
    padding: 8px 40px 8px 8px;
  }
}
