*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: sans-serif;
  line-height: 1.43;
  margin: 0;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2b2b2b;
}

.center {
  text-align: center;
}

.absolute-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 5;
}

.dot-divider {
  padding-right: .3em;
  padding-left: .3em;

  &::after {
    content: '\00B7';
  }
}

.pb-lead {
  font-size: 1.25rem;
}

.pb-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #8b8b8b;
  font-size: 64px;
  width: 1em;
  height: 1em;
  color: #8b8b8b;

  svg {
    height: 0.5em;
    width: 0.5em;
  }
}

.border-red {
  border-color: #F53636 !important;
}

.visually-hidden {
  text-indent: -1000em;
  margin: 0 !important;
  height: 1px;
}


.hidden {
  display: none !important;
}

.pb-title-prefix {
  color: rgba(0,0,0,.54);
  font-size: 14px;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.pb-mt-0 { margin-top: 0rem; }
.pb-mb-0 { margin-bottom: 0rem; }
.pb-mt-2 { margin-top: 0.5rem; }
.pb-mb-2 { margin-bottom: 0.5rem; }
.pb-mt-3 { margin-top: 1rem; }
.pb-mb-3 { margin-bottom: 1rem; }
.pb-mt-4 { margin-top: 2rem; }
.pb-mb-4 { margin-bottom: 2rem; }
.pb-mx-auto { margin-left: auto; margin-right: auto; }

.pb-width-50 {
  width: 50% !important;
}