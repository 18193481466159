.pb-blog-index {
  max-width: 778px;
  margin: 0 auto 2rem;

  .pb-title-prefix {
    margin-top: 2rem;
    text-align: center;
  }

  .pb-blog-index--cards .pb-blog-post-card:last-child {
    border-bottom: 0;
  }

  .pagination {
    border-top: 1px solid #d4d4d4;
  }
}

.pb-blog-post-card {
  position: relative;
  display: flex;
  border-bottom: 1px solid #d4d4d4;
  padding: 20px;

  .pb-blog-post-card--content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 128px;
  }

  .pb-blog-post-card--content {
    position: relative;
    z-index: 1;
  }
  
  img {
    position: relative;
    min-width: 128px;
    min-height: 128px;
    width: 128px;
    height: 128px;
    object-fit: cover;
    object-position: center;
    margin-right: 1.5rem;
    animation: linear 1s placeHolderShimmer infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 350% 100%;
    z-index: 1;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;
  }

  .pb-blog-post--excerpt {
    margin-top: 4px;
    color: rgba(black, 0.6);
  }

  .pb-blog-post--meta {
    margin-top: 8px;
    font-size: 80%;
    color: rgba(black, 0.6);
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% + 2rem);
    height: calc(100% + 4px);
    left: -1rem;
    top: -2px;
    transition: opacity .42s cubic-bezier(.165,.84,.44,1);
    background: white;
    opacity: 0;
  }

  @media screen and (min-width: 991px) {
    &:hover::before {
      opacity: 1;
      box-shadow: 0 0 40px rgba(0,0,0,.1);
    }
  }
}

.pb-blog-post-card.featured {
  border: 0;
  padding: 2rem;
  margin: 2rem auto;
  display: grid;
  grid-column-gap: 12%;
  grid-template-columns: repeat(2,1fr);
  min-height: 400px;
  max-width: 980px;

  img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
  }

  h3 {
    font-size: 3.125rem;
    line-height: 1.12;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  &::before {
    display: none;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .pb-blog-post-card--content-wrapper {
      display: block;
      min-height: 0;
    }

    img {
      max-width: 100%;
      max-height: 200px;
      margin-bottom: 0.75rem;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -300px 0;
  }
  100% {
    background-position: 300px 0;
  }
}

.pb-blog-post {
  max-width: 680px;
  margin: 0 auto 2rem;
  padding: 2rem;

  h1.title {
    margin: 0 0 1rem;
    font-size: 3.125rem;
    line-height: 1.12;
  }

  .trix-content {
    font-size: 1.17rem;
    line-height: 1.9;

    h1 {
      font-size: 1.67rem;
    }

    ul, ol {
      li {
        margin-left: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    color: rgba(0, 0, 0, 0.843137);

    h1.title {
      font-size: 2rem;
    }
  }
}

.pb-blog-post--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  color: rgba(0, 0, 0, 0.541176);
}

.pb-blog-post--header-left {
  display: flex;
  flex-direction: column;
}

.pb-blog-post--meta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .pb-blog-post--author {
    color: rgba(0, 0, 0, 0.843137);
  }

  .pb-blog-post--meta-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.pb-blog-post--sharing {
  margin-left: 1.5rem;
  font-size: 1.25rem;
  display: flex;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-decoration: none;
    color: white;
    font-size: 75%;
    margin-right: 6px;
    transition: 200ms all ease-in-out;

    svg {
      fill: white;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.pb-blog-post--progress-bar-wrapper {
  width: 100%;
  height: 6px;
  position: fixed;
  bottom: 0px;
  left: 0;
}

.pb-blog-post--progress-bar {
  height: 6px;
  width: 0%;
  background: linear-gradient(135deg,rgb(35, 146, 236),rgb(13, 92, 156));
}

.pagination {
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-item {
    display: flex;
    align-items: center;
    color: #2b2b2b;
    text-decoration: none;
    transition: 200ms all ease-in-out;
    font-weight: 600;

    svg {
      height: 1.2em;
      color: #2b2b2b;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.pb-signup-for-newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;
  text-align: center;

  .pb-icon-wrapper, .pb-checkmark {
    margin-bottom: 1rem;
  }

  .pb-lead {
    margin-bottom: 1rem;
  }

  .pb-form {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 360px;
  }

  .pb-button {
    margin-left: 0.25rem;
  }
}

.pb-tags {
  display: flex;
  flex-wrap: wrap;
}

.pb-tag {
  position: relative;
  display: block;
  padding: 5px 8px;
  margin: 5px 5px 5px 0;
  background-color: rgba(0, 0, 0, 0.0470588);
  color: rgba(0, 0, 0, 0.541176);
  line-height: 1;
  border-radius: 3px;
  font-size: 0.9rem;
  z-index: 10;
  text-decoration: none;
  transition: 200ms all ease-in-out;

  &:hover, &:focus, &:active {
    transform: scale(1.15);
    text-decoration: none;
    color: rgba(0, 0, 0, 0.541176);
  }
}