.pb-password-protected-page {
  overflow: hidden;

  .page-content {
    opacity: 0;
  }
}

.pb-password-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #e9e9e9;
  opacity: 1;
  transition: all 200ms ease-in;

  &.pb-hidden {
    opacity: 0;
  }
}

.pb-auth-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  max-width: 320px;

  .pb-icon-wrapper {
    margin-bottom: 1.5rem;
  }
}
