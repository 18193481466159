html, body {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

body {
  font-family: "Montserrat";
  line-height: 1.43;
  margin: 0;
  display: block;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

h1 {
  font-size: 4rem;
  @media (max-width: 991px) { font-size: 2rem; }
}

h2 {
  font-size: 2.25rem;
  @media (max-width: 991px) { font-size: 1.85rem; }
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

a {
  color: $secondary;
}

.editorial {
  line-height: 1.8;
}

.uppercase {
  text-transform: uppercase;
}

.hide, .hidden {
  display: none;
}

.center, .centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold, strong {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.no-effects {
  &:hover, &:focus, &:active { outline: none; }
}

.white-letters {
  color: white;
  h1, h2, h3, h4, h5, h6, p, a, ul, li {
    color: white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.bring-to-front {
  z-index: 100;
}

.hidden-mobile {
  @media(max-width: 991px) {
    display: none;
  }
}

.centered-mobile {
  @media(max-width: 991px) {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}
